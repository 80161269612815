<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div>
    <egw-table :is-row-click="isRowClick" :form-options="formOptions" :export-data-file="exportServer"
      :delete-data="deleteServer" :add-data="addServer" :update-data="updateServer" :info-title="infoTitle"
      :add-title="addTitle" :edit-title="editTitle" :drawerform="drawerform" :form="form" :is-operation="isOperation"
      :operates="operates" :export-file-name="exportFileName" :retrieve-data="retrieveData" :after-retrieve="afterList"
      :before-request="beforeRequest" :selection="selection" :columns="columns" :rules="rules"
      @rowOperation="rowOperation" @handleEchart="handleEchart" />
  </div>
</template>

<script>
import { getServerList, addServer, updateServer, deleteServer, exportServersAsExcelFile } from '../api/ajax-server'
export default {
  name: 'Test',
  data() {
    return {
      retrieveData: getServerList,
      exportServer: exportServersAsExcelFile,
      deleteServer: deleteServer,
      addServer: addServer,
      updateServer: updateServer,
      afterList: this.afterServerList,
      beforeRequest: this.beforeRequset,
      selection: true,
      // 查询表单的数据
      formOptions: [
        // { label: this.$t('server.label.serverId'), prop: 'id', type: 'input' },
        { label: this.$t('server.label.serverName'), prop: 'name', type: 'input' },
        { label: this.$t('server.label.serverIp'), prop: 'ipAddress', type: 'input' },
        {
          label: this.$t('server.label.machineType'), prop: 'machineType', type: 'select',
          selectOptions: [
            { value: 1, label: this.$t('server.machineType.physical') },
            { value: 2, label: this.$t('server.machineType.virtual') },
            { value: 3, label: this.$t('server.machineType.docker') }
          ]
        }
      ],
      // 表格内是否显示操作
      isOperation: true,
      // 点击某一行是否显示详情信息
      isRowClick: false,
      // 表格内的操作
      operates: [
        { icon: 'el-icon-notebook-2', text: this.$t('operate.look'), operation: 'info' },
        { icon: 'el-icon-edit', text: this.$t('operate.edit'), operation: 'edit' },
        { icon: 'el-icon-delete', text: this.$t('operate.delete'), operation: 'delete' },
        { icon: 'el-icon-d-arrow-right', text: this.$t('operate.inspectionLog'), operation: 'echart' }
      ],
      exportFileName: this.$t('export.serverInfo'),
      // 表格表头
      columns: [
        { prop: 'id', label: this.$t('server.label.serverId') },
        { prop: 'name', label: this.$t('server.label.serverName') },
        { prop: 'ipAddress', label: this.$t('server.label.ipAddress') },
        { prop: 'hostName', label: this.$t('server.label.hostName') },
        { prop: 'machineType', label: this.$t('server.label.machineType') },
        { prop: 'contactId', label: this.$t('server.label.contactId') },
        { prop: 'memory', label: this.$t('server.label.memory') + '(MB)' },
        { prop: 'diskSpace', label: this.$t('server.label.diskSpace') + '(MB)' },
        { prop: 'registeredUserId', label: this.$t('server.label.registeredUserId') },
        { prop: 'registeredTime', label: this.$t('server.label.registeredTime') },
        { prop: 'updatedUserId', label: this.$t('server.label.updatedUserId') },
        { prop: 'updatedTime', label: this.$t('server.label.updatedTime') }
      ],
      // 详情标题
      infoTitle: this.$t('server.title.serverInfo'),
      // 注册的标题
      addTitle: this.$t('server.title.register'),
      // 编辑的标题
      editTitle: this.$t('server.title.edit'),
      // 详情信息的标题和label
      drawerform: [
        { prop: 'id', label: this.$t('server.label.serverId') },
        { prop: 'name', label: this.$t('server.label.serverName') },
        { prop: 'ipAddress', label: this.$t('server.label.ipAddress') },
        { prop: 'hostName', label: this.$t('server.label.hostName') },
        { prop: 'machineType', label: this.$t('server.label.machineType') },
        { prop: 'contactId', label: this.$t('server.label.contactId') },
        { prop: 'memory', label: this.$t('server.label.memory') + '(MB)' },
        { prop: 'diskSpace', label: this.$t('server.label.diskSpace') + '(MB)' },
        { prop: 'inspectionPeriod', label: this.$t('server.label.inspectionPeriod') },
        { prop: 'inspectionConfig', label: this.$t('server.label.inspectionConfig'), span: 24, type: 'textarea' }
      ],
      // 新增和编辑的表单字段
      // 这里的disabled指的是编辑时不可编辑
      form: [
        { prop: 'id', label: this.$t('server.label.serverId'), disabled: true, type: 'input' },
        { prop: 'name', label: this.$t('server.label.serverName'), type: 'input' },
        { prop: 'ipAddress', label: this.$t('server.label.ipAddress'), type: 'input' },
        { prop: 'hostName', label: this.$t('server.label.hostName'), type: 'input' },
        {
          prop: 'machineType', label: this.$t('server.label.machineType'), type: 'select',
          selectOptions: [
            { value: 1, label: this.$t('server.machineType.physical') },
            { value: 2, label: this.$t('server.machineType.virtual') },
            { value: 3, label: this.$t('server.machineType.docker') }
          ]
        },
        { prop: 'contactId', label: this.$t('server.label.contactId'), type: 'input' },
        { prop: 'memory', label: this.$t('server.label.memory') + '(MB)', type: 'input' },
        { prop: 'diskSpace', label: this.$t('server.label.diskSpace') + '(MB)', type: 'input' },
        { prop: 'remark', label: this.$t('server.label.remark'), type: 'input' },
        { prop: 'inspectionPeriod', label: this.$t('server.label.inspectionPeriod'), type: 'input' },
        { prop: 'inspectionConfig', label: this.$t('server.label.inspectionConfig'), span: 24, type: 'keyValue' }
      ],
      // 新增和编辑的表单验证
      rules: {
        id: [
          { required: true, message: this.$t('server.rules.message.id'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('server.rules.message.name'), trigger: 'blur' }
        ],
        ipAddress: [
          { required: true, message: this.$t('server.rules.message.ipAddress'), trigger: 'blur' }
        ],
        hostName: [
          { required: true, message: this.$t('server.rules.message.hostName'), trigger: 'blur' }
        ],
        memory: [
          { required: true, message: this.$t('server.rules.message.memory'), trigger: 'blur' }
        ],
        diskSpace: [
          { required: true, message: this.$t('server.rules.message.diskSpace'), trigger: 'blur' }
        ],
        contactId: [
          { required: true, message: this.$t('server.rules.message.contactId'), trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    // 请求列表之后处理的事件
    afterServerList(list) {
      list.forEach(item => {
        item.updatedTime = this.changeTime(item.updatedTime)
        item.registeredTime = this.changeTime(item.registeredTime)
        this.changeMachineType(item)
        item.config = this.getConfig(item)
      })
      // console.log(list)
      return list
    },
    // 发送请求之前处理的事件
    beforeRequset(data) {
      if (data.machineType === this.$t('server.machineType.physical')) {
        data.machineType = 1
      } else if (data.machineType === this.$t('server.machineType.virtual')) {
        data.machineType = 2
      } else if (data.machineType === this.$t('server.machineType.docker')) {
        data.machineType = 3
      }
      return data
    },
    /**
    * @methods changeMachineType
    * @description 判断机器类型转换为i18n对应的字段
    * @param {Object} params 包含机器类型的数据
    */
    changeMachineType(item) {
      if (item.machineType === 1) {
        item.machineType = this.$t('server.machineType.physical')
      } else if (item.machineType === 2) {
        item.machineType = this.$t('server.machineType.virtual')
      } else if (item.machineType === 3) {
        item.machineType = this.$t('server.machineType.docker')
      }
    },
    /**
    * @methods getConfig
    * @description inspectionConfig格式转换为json字符串
    * @param {Object} params 获取到的inspectionConfig
    */
    getConfig(data) {
      const inspectionConfigObject = JSON.parse(data.inspectionConfig)
      const array = []
      for (const key in inspectionConfigObject) {
        const object = {}
        object[key] = inspectionConfigObject[key]
        array.push(object)
      }
      const jsonObject = {}
      array.forEach(item => {
        const key = Object.keys(item)
        const value = item[key]
        jsonObject[key] = value
      })
      return array
    },
    // 操作按钮触发事件
    rowOperation(row, $index, now) {
      console.log(row, $index, now)
    },
    // 巡检日志触发事件
    handleEchart(row) {
      this.$router.push({
        path: '/test2',
        query: {
          serverId: row.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
